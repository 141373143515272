import BannerImage from './components/bannerImage'
import HomeSlider from './components/homeSlider'
import Mission from './components/mission'
import ProjectsCount from './components/projectsCount'
import Image from '../assets/img/HomeBanner.webp'
export default function Home () {
  return (
    <>
      <BannerImage image={Image} />
      <ProjectsCount />
      <HomeSlider />
      <Mission />
    </>
  )
}
