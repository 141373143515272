import React, { useRef, useEffect } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  List,
  ListItem,
  ListItemPrefix
} from '@material-tailwind/react'
import emailjs from '@emailjs/browser'
import LocationImg from '../assets/img/icons/location.png'
import EmailImg from '../assets/img/icons/email.png'
import InstaImg from '../assets/img/icons/instagram.png'
import Subscribe from './components/subscribe'
import ContactForm from './components/contactForm'
export default function Contact () {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_ylg1z7j',
        'template_kcwx3nq',
        form.current,
        '7rEmscGWI0KKCHRSx'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    form.current.reset()
  }
  return (
    <>
      <div className='w-full py-24'>
        <div className='flex max-w-screen-xl mx-auto'>
          <Card className='flex-row w-full max-md:flex-col'>
            <CardHeader
              shadow={false}
              floated={false}
              className='py-12 m-0 rounded-r-none md:w-1/2 bg-main shrink-0'
            >
              <ContactForm />
              {/* <form
                className='flex flex-col space-y-3'
                ref={form}
                onSubmit={sendEmail}
              >
                <div className='px-4'>
                  <label className='block w-full text-white' for='name'>
                    Name.
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='w-full text-white bg-transparent border-t-0 border-l-0 border-r-0 border-b-white focus:border-b-white placeholder:text-white focus:outline-none focus:ring-0'
                    required
                    id='name'
                  />
                </div>
                <div className='px-4'>
                  <label className='block w-full text-white' for='email'>
                    Email.
                  </label>
                  <input
                    type='email'
                    name='email'
                    className='w-full text-white bg-transparent border-t-0 border-l-0 border-r-0 border-b-white focus:border-b-white placeholder:text-white focus:outline-none focus:ring-0 '
                    required
                    id='email'
                  />
                </div>
                <div className='px-4'>
                  <label className='block w-full text-white' for='tel'>
                    Tel.
                  </label>
                  <input
                    type='number'
                    name='tel'
                    className='w-full text-white bg-transparent border-t-0 border-l-0 border-r-0 border-b-white focus:border-b-white placeholder:text-white focus:outline-none focus:ring-0 '
                    id='tel'
                  />
                </div>
                <div className='px-4'>
                  <label for='message' className='block w-full text-white'>
                    Message.
                  </label>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows=''
                    className='w-full text-white bg-transparent border-t-0 border-l-0 border-r-0 border-b-white focus:border-b-white placeholder:text-white focus:outline-none focus:ring-0'
                  ></textarea>
                </div>
                <div className='px-4'>
                  <button
                    type='submit'
                    className='w-full py-2 font-bold text-white bg-black'
                  >
                    Send
                  </button>
                </div>
              </form> */}
            </CardHeader>
            <CardBody className='flex flex-col justify-center'>
              <Typography
                variant='h4'
                color='blue-gray'
                className='mb-2 text-3xl text-main'
              >
                Contact Us
              </Typography>
              <List className='p-0 my-2 '>
                <ListItem className='px-2 py-2 rounded-none group sm:text-xl text-main'>
                  <ListItemPrefix>
                    <img
                      src={LocationImg}
                      className='w-6 transition-all duration-300 sm:w-10 group-hover:-translate-y-2'
                    />
                  </ListItemPrefix>
                  1812 W. Burbank Blvd. #295, Burbank, CA 91506
                </ListItem>
                <ListItem className='px-2 py-2 rounded-none group sm:text-xl text-main'>
                  <ListItemPrefix>
                    <img
                      src={EmailImg}
                      className='w-6 transition-all duration-300 sm:w-10 group-hover:-translate-y-2'
                    />
                  </ListItemPrefix>
                  <a href='mailto:info@gatewayindustry.org'>
                    info@gatewayindustry.org
                  </a>
                </ListItem>
                <ListItem className='px-2 py-2 rounded-none group sm:text-xl text-main'>
                  <ListItemPrefix>
                    <img
                      src={InstaImg}
                      className='w-6 transition-all duration-300 sm:w-10 group-hover:-translate-y-2'
                    />
                  </ListItemPrefix>
                  <a href='https://instagram.com/gatewaynpo?igshid=MmU2YjMzNjRlOQ=='>
                    Instagram
                  </a>
                </ListItem>
              </List>
              <Subscribe />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}
