import { Typography } from '@material-tailwind/react'
import CardText from './components/cardText'

export default function OurStory () {
  const list = [
    {
      text: 'Gateway Industry Inc. was formally established as non-profit organization in 2013. Its founders – Sarkis Kiramijyan, Armen Kiramijyan and Robert Tingilian – started a tradition of hosting an annual get-together on the first Sunday of the year to celebrate the New Year and the Armenian Christmas over a morning dish of “Xash”. Xash is a traditional Armenian soup of boiled cow hooves that is served during the cold Armenian winter to warm the body and be a source of minerals from the bone marrow of the beef. The primary purpose of this collective event is to bring together like-minded professionals and to create an energetic forum to foster and strengthen the Armenian spirit for the overall empowerment of our families, our communities and our homeland. The event usually included a fundraiser to support an education project in Armenia.'
    },
    {
      text: 'Over the years, through the vision of its leaders and the generous support of its donors and members, Gateway Industry has been able to make significant contributions and create gateways for growth and development in the areas of health, education and technology, as well as important humanitarian initiatives.'
    }
  ]

  return (
    <>
      <CardText title={'Our Story'} data={list} />
    </>
  )
}
