import { Typography } from '@material-tailwind/react'
import CardText from './components/cardText'
export default function OurMission () {
  const list = [
    {
      text: 'Gateway Industry Inc. is an association of like-minded professionals who have come together with the determination of improving their families and communities in the United States and in Armenia. The members of the association strongly believe that a unified effort produces exponential effects in any work that is undertaken.'
    },
    {
      text: 'The mission of Gateway Industry Inc. is to unite the members and supporters of Armenian communities in the diaspora in order to strengthen the Armenian spirit for the overall empowerment of our families, our communities and our homeland. Furthermore, our unity and resolve will enable us to create gateways for growth and development in the areas of education, healthcare and technology for the Armenian communities in the United States of America and in Armenia.'
    },
    {
      text: 'Gateway Industry Inc. is committed to promoting a culture of unity, selflessness and philanthropy to contribute to the advancement of Armenia and to secure a vibrant Armenian future for generations to come.'
    },
    {
      text: 'Gateway Industry is a 501(c)(3) non-profit organization registered in the USA.'
    }
  ]

  return (
    <>
      <CardText title={'Our Mission'} data={list} />
    </>
  )
}
