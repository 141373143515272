import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip
} from '@material-tailwind/react'
import SarqisImg from '../assets/img/team/Sargis.PNG'
import ArmenImg from '../assets/img/team/Armen.JPG'
import RobertImg from '../assets/img/team/Robert.JPG'
import NareImg from '../assets/img/team/Nare.PNG'
import AraqsyaImg from '../assets/img/team/Araqsya.PNG'
export default function OurTeam () {
  return (
    <>
      <div className='w-full py-24 max-xl:px-6'>
        <div className='max-w-screen-xl mx-auto'>
          <Typography className='text-xl font-bold sm:text-3xl text-main'>
            Our Team
          </Typography>
          <div className='flex w-full py-12 lg:space-x-4 max-lg:space-y-6 max-lg:items-center max-lg:flex-col xl:space-x-6'>
            <Card className=' w-fit lg:w-1/3'>
              <CardHeader floated={false} className=''>
                <img
                  src={SarqisImg}
                  className=''
                  alt='profile-picture'
                />
              </CardHeader>
              <CardBody className='text-center'>
                <Typography
                  variant='h4'
                  color='blue-gray'
                  className='mb-2 text-main'
                >
                  Sarkis Kiramijyan
                </Typography>
              </CardBody>
            </Card>
            <Card className='w-fit lg:w-1/3'>
              <CardHeader floated={false} className=''>
                <img
                  src={ArmenImg}
                  className=''
                  alt='profile-picture'
                />
              </CardHeader>
              <CardBody className='text-center'>
                <Typography
                  variant='h4'
                  color='blue-gray'
                  className='mb-2 text-main'
                >
                  Armen Kiramijyan
                </Typography>
              </CardBody>
            </Card>
            <Card className='w-fit lg:w-1/3'>
              <CardHeader floated={false} className=''>
                <img
                  src={RobertImg}
                  className=''
                  alt='profile-picture'
                />
              </CardHeader>
              <CardBody className='text-center'>
                <Typography
                  variant='h4'
                  color='blue-gray'
                  className='mb-2 text-main'
                >
                  Robert Tingilian
                </Typography>
              </CardBody>
            </Card>
          </div>
          <div className='flex w-full py-12 lg:space-x-4 max-lg:space-y-6 max-lg:items-center max-lg:flex-col xl:space-x-6'>
            <Card className='w-fit lg:w-1/3'>
              <CardHeader floated={false} className=''>
                <img
                  src={NareImg}
                  className=''
                  alt='profile-picture'
                />
              </CardHeader>
              <CardBody className='text-center'>
                <Typography
                  variant='h4'
                  color='blue-gray'
                  className='mb-2 text-main'
                >
                  Nare Harutyunyan
                </Typography>
              </CardBody>
            </Card>
            <Card className='w-fit lg:w-1/3'>
              <CardHeader floated={false} className=''>
                <img
                  src={AraqsyaImg}
                  className=''
                  alt='profile-picture'
                />
              </CardHeader>
              <CardBody className='text-center'>
                <Typography
                  variant='h4'
                  color='blue-gray'
                  className='mb-2 text-main'
                >
                  Araksya Arakelyan
                </Typography>
              </CardBody>
            </Card>
            <Card className='opacity-0 w-fit lg:w-1/3'></Card>
          </div>
        </div>
      </div>
    </>
  )
}
