import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { Input } from '@material-tailwind/react'
export default function ContactForm () {
  const form = useRef()
  
  const [message , setMessage] = useState('')

  const sendEmail = e => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_r14oime',
        'template_d51m3n7',
        form.current,
        'cyo_5BFcqeRGomG77'
      )
      .then(
        result => {
          console.log(result.text)
          setMessage(
            'Your message has been successfully sent.'
          )
        },
        error => {
          console.log(error.text)
          setMessage(
            'Something went wrong. Please try again later.'
          )
        }
      )
    form.current.reset()
  }

  return (
    <>
      <form
        className='flex flex-col px-6 space-y-6 Contact__Form'
        ref={form}
        onSubmit={sendEmail}
      >
        <Input
          name='name'
          variant='standard'
          label='Name.'
          color='white'
          required
          className='peer w-full h-full bg-transparent text-white font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-blue-gray-200 text-lg pt-4 pb-1.5 border-blue-gray-200 focus:border-white'
        />
        <Input
          name='email'
          variant='standard'
          label='Email.'
          color='white'
          required
          // type='email'
          className='peer w-full h-full bg-transparent text-white font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-blue-gray-200 text-lg pt-4 pb-1.5 border-blue-gray-200 focus:border-white'
        />
        <Input
          name='tel'
          variant='standard'
          label='Tel.'
          color='white'
          // type='number'
          required
          className='peer w-full h-full bg-transparent text-white font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-blue-gray-200 text-lg pt-4 pb-1.5 border-blue-gray-200 focus:border-white'
        />
        <textarea
          name='message'
          id=''
          cols='30'
          rows=''
          placeholder='Message'
          className='w-full text-white bg-transparent border-t-0 border-l-0 border-r-0 border-b-white focus:border-b-white placeholder:text-white focus:outline-none focus:ring-0'
        >
        </textarea>
        <button
          type='submit'
          className='w-full py-2 font-bold text-white bg-black rounded-lg'
        >
          Send
        </button>
        {message ? (
              <div className='text-lg text-white'>
                {message}
              </div>
            ) : (
              ''
            )}
      </form>
    </>
  )
}
