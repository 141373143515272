import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
export default function PayPal () {
  return (
    <>
      <div className='py-24'>
        <div className='flex justify-center max-w-screen-xl py-12 mx-auto shadow-lg shadow-main'>
          <PayPalScriptProvider>
            <PayPalButtons />
          </PayPalScriptProvider>
        </div>
      </div>
    </>
  )
}
