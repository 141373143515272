import BannerImg from '../assets/img/Rehabilitation-1.png'
import img1 from '../assets/img/health/264244008_357079829520789_6816894866724625058_n.jpg'
import img2 from '../assets/img/health/264335251_357079786187460_8043640543014858925_n.jpg'
import img3 from '../assets/img/health/264551324_357079799520792_8691489112099710876_n (1).jpg'
import img5 from '../assets/img/health/264673390_357079796187459_6075180127755663079_n.jpg'
import BannerImage from './components/bannerImage'
import CardText from './components/cardText'
export default function GatewayToHealth () {
  const list = [
    {
      text: 'As a result of the 2020 Artsakh War, an estimated 9,000 soldiers sustained various degrees of physical injury and psychological trauma. They faced significant difficulties related to accessing government-funded services; obtaining the necessary care for physical or psychological rehabilitation; and navigating the social and cultural stigmas associates with disability and PTSD. Based on a comprehensive needs-assessment project performed by the Gateway Industry founders and members during their trip to post-war Armenia in December 2020, two important needs were identified: 1. A need for re-training currently practicing physiotherapists and kinesiologists in modern technologies and clinical methodologies. 2. A significant need for physiotherapy services in the various regions of Armenia, outside of the capital city of Yerevan. To address the identified needs, in July 2021, Gateway Industry Inc. launched a Veteran Physiotherapy and Rehabilitation Project in collaboration with the Scientific Research Institute of Spa Treatment and Physical Medicine. As part of the project, veterans from various regions of Armenia who have a need for physical and/or psychological therapy services but are no longer eligible for state-sponsored funding are transported to the Orbeli Institute Hospital (also known as “The Scientific Research Institute of Spa Treatment and Physical Medicine) for a full-course of physiotherapy and psychological services. Gateway Industry fully funds the cost of transportation, daily meals and medical treatments for these veterans.'
    },
    {
      text: 'Dr. Anahit Isakhanyan, MD and Physiotherapist, Asbed Kelkhacheryan visit Ararat region to perform an assessment of the physiotherapy needs of former servicemen in the Ararat regions and to enroll them in the Veteran Physiotherapy project for medical treatment at the Orbeli Hospital.'
    }
  ]

  return (
    <>
      <BannerImage image={BannerImg} />
      <div className='max-w-screen-xl py-12 mx-auto max-xl:px-6'>
        <div className='flex md:space-x-4 max-md:flex-wrap'>
          <div className='p-2 sm:w-1/2 md:w-1/4'>
            <img src={img1} alt='' />
          </div>
          <div className='p-2 sm:w-1/2 md:w-1/4'>
            <img src={img2} alt='' />
          </div>
          <div className='p-2 sm:w-1/2 md:w-1/4'>
            <img src={img3} alt='' />
          </div>
          <div className='p-2 sm:w-1/2 md:w-1/4'>
            <img src={img5} alt='' />
          </div>
        </div>
      </div>
      <CardText
        title={'GATEWAY TO HEALTH: Veterans Rehab Project'}
        data={list}
      />
    </>
  )
}
