import Img from '../assets/img/photo_2023-07-14_14-05-19.jpg'
export default function Reports () {
  return (
    <>
      <div className='py-24'>
        <div className='max-w-screen-xl px-6 py-12 mx-auto shadow-sm shadow-main hover:shadow-lg hover:shadow-main'>
          <a
            href='https://drive.google.com/file/d/1xmRxSgtLaSZd36LElh_3lcaQaTPU-Kwv/view?usp=drive_link'
            target='_blank'
            className='text-lg font-bold text-main'
          >
            <div>2021-2022 Annual Report</div>
            <div className='mt-4'>
                <img src={Img} alt="" />
            </div>
          </a>
        </div>
      </div>
    </>
  )
}
