import CountUp from 'react-countup'
import BeneficariesImage from '../../assets/img/Beneficieries .png'
import ProjectsImage from '../../assets/img/Projects (2).png'
import ComunitiesImage from '../../assets/img/Communities (2).png'
import InovateImage from '../../assets/img/Innovated-infstructures.png'
import SchoolImage from '../../assets/img/Scholarships (2).png'
export default function ProjectsCount () {
  const list = [
    {
      image: BeneficariesImage,
      title: 'BENEFICIARIES',
      duration: 3,
      end: 1160
    },
    {
      image: ProjectsImage,
      title: 'PROJECTS',
      duration: 1,
      end: 8
    },
    {
      image: ComunitiesImage,
      title: 'COMMUNITIES',
      duration: 2,
      end: 57
    },
    {
      image: InovateImage,
      title: 'INFRASTRUCTURE RENOVATION',
      duration: 1,
      end: 6
    },
    {
      image: SchoolImage,
      title: 'SCHOLARSHIPS',
      duration: 1,
      end: 8
    }
  ]

  return (
    <>
      <div className='w-full py-12 bg-center bg-cover sm:py-24 max-xl:px-6 bg-projects-count-bg'>
        <div className='flex items-end max-w-screen-xl mx-auto max-md:flex-wrap'>
          {list.map((item, index) => {
            return (
              <div className='w-full sm:w-1/2 lg:w-1/5' key={index}>
                <div className='flex justify-center'>
                  <img src={item.image} className='h-24 sm:h-48' alt='' />
                </div>
                <div className='text-xl text-center text-white'>
                  {item.title}
                </div>
                <div className='mt-2 text-2xl text-center text-white'>
                  <CountUp
                    start={0}
                    duration={item.duration}
                    delay={0.3}
                    end={item.end}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
