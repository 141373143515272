import React from "react";
import { Routes, Route } from "react-router-dom";
import './index.css';
import Home from "./Pages/home";
import Header from "./Pages/layouts/header";
import OurMission from "./Pages/ourMission";
import OurStory from "./Pages/ourStory";
import OurTeam from "./Pages/ourTeam";
import Reports from "./Pages/reports";
import GatewayToEducationNewPath from "./Pages/gatewayToEducationNewPath";
import GatewayToHealth from "./Pages/gatewayToHealth";
import GatewayTechnologies from "./Pages/gatewayTechnologies";
import TeacherRetrainingProject from './Pages/teacherRetrainingProject';
import TumoKidsTransportation from './Pages/tumoKidsTransportation';
import Contact from "./Pages/contact";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Pages/layouts/footer";
import PayPal from "./Pages/components/paypal";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='' element={<Home />} />
        <Route path='/about-us/our-mission' element={<OurMission />} />
        <Route path='/about-us/our-story' element={<OurStory />} />
        <Route path='/about-us/our-team' element={<OurTeam />} />
        <Route path='/about-us/reports' element={<Reports />} />
        <Route path='our-work/gateway-to-education/new-path' element={<GatewayToEducationNewPath />} />
        <Route path='our-work/gateway-to-education/teacher-retraining-project' element={<TeacherRetrainingProject />} />
        <Route path='our-work/gateway-to-education/tumo-kids-transportation' element={<TumoKidsTransportation />} />
        <Route path='our-work/gateway-to-health' element={<GatewayToHealth />} />
        <Route path='our-work/gateway-to-science-and-technology' element={<GatewayTechnologies />} />
        <Route path="contact" element={<Contact />} />
        <Route path="donate" element={<PayPal />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
