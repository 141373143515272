import Slider from 'react-slick'
import Slider1Image from "../../assets/img/New-path-5.png"
import Slider2Image from "../../assets/img/Rehabilitation-1.png"
import Slider3Image from "../../assets/img/t.png"
import Slider4Image from "../../assets/img/tumo.png"
import Slider5Image from "../../assets/img/Website-visual-2.png"

export default function HomeSlider () {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade:true,
    autoplaySpeed: 2000
  }

  return (
    <>
      <div className='w-full overflow-hidden Right__Slider'>
        <Slider {...settings}>
          <div className='max-sm:h-48'>
            <img src={Slider1Image} className='w-full h-full' alt="Our Projects Image" />
          </div>
          <div className='max-sm:h-48'>
            <img src={Slider2Image} className='w-full h-full' alt="Our Projects Image" />
          </div>
          <div className='max-sm:h-48'>
            <img src={Slider3Image} className='w-full h-full' alt="Our Projects Image" />
          </div>
          <div className='max-sm:h-48'>
            <img src={Slider4Image} className='w-full h-full' alt="Our Projects Image" />
          </div>
          <div className='max-sm:h-48'>
            <img src={Slider5Image} alt="Our Projects Image" />
          </div>
        </Slider>
      </div>
    </>
  )
}
