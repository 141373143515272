import React, {useRef , useState} from 'react'
import emailjs from '@emailjs/browser'

export default function Subscribe () {
  const form = useRef()
  const [message , setMessage] = useState('')
  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_r14oime',
        'template_mei1zte',
        form.current,
        'cyo_5BFcqeRGomG77'
      )
      .then(
        result => {
          console.log(result.text)
          setMessage(
            'You have successfully subscribed'
          )
        },
        error => {
          console.log(error.text)
        }
      )
    form.current.reset()
  }

  return (
    <>
      <div className='flex flex-col'>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' className='w-full h-12 rounded-md shadow-none focus:shadow-none placeholder:text-main border-main focus:border-main focus:outline-none' placeholder='Your Email' name='email' />
          <button className='w-full h-12 mt-3 text-white rounded-md bg-main' type='submit'>Subscribe</button>
        </form>
        {message ? (
              <div className='mt-1 text-sm text-main'>
                {message}
              </div>
            ) : (
              ''
            )}
      </div>
    </>
  )
}
