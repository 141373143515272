import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/Gateway-Industry-red.png'
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem
} from '@material-tailwind/react'
import Subscribe from '../components/subscribe'
import PayPal from '../components/paypal'

export default function Header () {
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])
  const closeMenu = () => {
    setOpenNav(false)
  }
  const navList = (
    <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          className='flex items-center text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
          onClick={closeMenu}
        >
          Home
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Menu>
          <MenuHandler>
            <Button
              title='Click'
              className='p-0 text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
            >
              About
            </Button>
          </MenuHandler>
          <MenuList className='relative z-50 text-white bg-black'>
            <MenuItem className='text-left'>
              <Link
                to='/about-us/our-mission'
                className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                onClick={closeMenu}
              >
                Our Mission
              </Link>
            </MenuItem>
            <MenuItem className='text-left'>
              <Link
                to='/about-us/our-story'
                className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                onClick={closeMenu}
              >
                Our Story
              </Link>
            </MenuItem>
            <MenuItem className='text-left'>
              <Link
                to='/about-us/our-team'
                className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                onClick={closeMenu}
              >
                Our Team
              </Link>
            </MenuItem>
            <MenuItem className='text-left'>
              <Link
                to='/about-us/reports'
                className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                onClick={closeMenu}
              >
                Reports
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Menu>
          <MenuHandler>
            <Button
              title='Click'
              className='p-0 text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
            >
              Our Work
            </Button>
          </MenuHandler>
          <MenuList className='relative z-50 text-white bg-black'>
            <MenuItem className='text-left'>
              <Menu placement='right-start'>
                <MenuHandler>
                  <Button
                    title='Click'
                    className='p-0 text-base font-medium text-white capitalize transition-all duration-300 bg-transparent shadow-none outline-none hover:text-main hover:shadow-none'
                  >
                    Getway to Education
                  </Button>
                </MenuHandler>
                <MenuList className='relative z-50 text-white bg-black'>
                  <MenuItem>
                    <Link
                      to='our-work/gateway-to-education/new-path'
                      className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                      onClick={closeMenu}
                    >
                      New Path
                    </Link>
                  </MenuItem>
                  <MenuItem className=''>
                    <Link
                      to='our-work/gateway-to-education/teacher-retraining-project'
                      className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                      onClick={closeMenu}
                    >
                      Teacher Retraining Project
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to='our-work/gateway-to-education/tumo-kids-transportation'
                      className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                      onClick={closeMenu}
                    >
                      Tumo Kids Transportation
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu>
            </MenuItem>
            <MenuItem className='text-left'>
              <Link
                to='our-work/gateway-to-health'
                className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                onClick={closeMenu}
              >
                Getway to Health
              </Link>
            </MenuItem>
            <MenuItem className='text-left'>
              <Link
                to='our-work/gateway-to-science-and-technology'
                className='text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
                onClick={closeMenu}
              >
                Gateway to Science <br /> and Technology
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      </Typography>

      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to='/contact'
          className='flex items-center text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
          onClick={closeMenu}
        >
          Contact
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        {/* <Link
          to='/donate'
          className='flex items-center text-base font-medium text-white capitalize transition-all duration-300 hover:text-main'
          onClick={closeMenu}
        >
          Donate
        </Link> */}
      </Typography>
    </ul>
  )

  return (
    <>
      <div className='sticky top-0 z-50 w-full bg-black' id='Menu'>
        <Navbar className='relative z-50 max-w-screen-xl px-4 py-4 mx-auto bg-transparent border-none lg:px-8'>
          <div className='container flex items-center justify-between mx-auto text-blue-gray-900'>
            <Typography
              as='a'
              href='#'
              className='mr-4 font-medium cursor-pointer'
            >
              <Link to=''>
                <img src={Logo} className='w-40' alt='' />
              </Link>
            </Typography>
            <div className='hidden lg:block'>{navList}</div>
            <Menu className=''>
              <MenuHandler className='text-lg capitalize max-lg:hidden text-main'>
                <Button className=''>Subscribe</Button>
              </MenuHandler>
              <MenuList className='relative z-50'>
                <Subscribe />
              </MenuList>
            </Menu>
            <IconButton
              variant='text'
              className='w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden'
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  className='w-6 h-6'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              )}
            </IconButton>
          </div>
          <MobileNav open={openNav}>
            <div className='container mx-auto'>
              {navList}
              <Menu className='!p-0 m-0 lg:hidden'>
                <MenuHandler>
                  <Button className="p-1 m-0 text-lg text-main">Subscribe</Button>
                </MenuHandler>
                <MenuList className='relative z-50'>
                  <Subscribe />
                </MenuList>
              </Menu>
            </div>
          </MobileNav>
        </Navbar>
      </div>
    </>
  )
}
