import {
  Card,
  CardHeader,
  CardBody,
  Typography
} from '@material-tailwind/react'
export default function CardImage (props) {
  return (
    <>
      <Card className={`${props.flex} static !z-0 w-full bg-transparent shadow-none max-md:flex-col`}>
        <CardHeader
          shadow={false}
          floated={false}
          className='static z-0 m-0 rounded-r-none md:w-1/2 shrink-0'
        >
          <img
            src={props.image}
            alt={props.title}
            className='static z-0 object-cover w-full h-full'
          />
        </CardHeader>
        <CardBody className='flex flex-col justify-center'>
          <Typography variant='h4' color='blue-gray' className='mb-2 text-main'>
            {props.title}
          </Typography>
          <Typography color='gray' className='mb-8 font-normal text-black/60'>
            {props.text}
          </Typography>
        </CardBody>
      </Card>
    </>
  )
}
