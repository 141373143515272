import { Typography } from '@material-tailwind/react'

export default function CardText (props) {
  return (
    <>
      <div className='w-full py-12'>
        <div className='max-w-screen-xl py-12 mx-auto transition-all duration-300 rounded-lg shadow-sm shadow-main hover:shadow-lg hover:shadow-main'>
          <div className='max-w-screen-lg mx-auto max-xl:px-6'>
            <Typography className='text-xl font-bold sm:text-3xl text-main'>
              {props.title}
            </Typography>
            {props.data &&
              props.data.map((item, index) => {
                return (
                  <Typography className='mt-4 font-thin text-black text-md sm:font-medium sm:text-lg sm:text-black/90'>
                    {item.text}
                  </Typography>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}
