import {
  List,
  ListItem,
  ListItemPrefix,
  Card
} from '@material-tailwind/react'

import LocationImg from '../../assets/img/icons/location.png';
import EmailImg from '../../assets/img/icons/email.png'
import InstaImg from '../../assets/img/icons/instagram.png'
import TelImg from '../../assets/img/icons/tel.png'
export default function Footer () {
  
  const listUsa = [
    {
      image:LocationImg, href:"https://goo.gl/maps/NsPmgB9baH5bBqzY9", text:"1812 W. Burbank Blvd. #295, Burbank, CA 91506"
    },
    {
      image:EmailImg, href:"mailto:info@gatewayindustry.org", text:"info@gatewayindustry.org"
    },
    {
      image:InstaImg, href:"https://instagram.com/gatewaynpo?igshid=MmU2YjMzNjRlOQ==", text:"Instagram"
    },
  ]
  const listAm = [
    {
      image:LocationImg, href:"https://goo.gl/maps/EUfiJ5PM56hmB96z7", text:"23/3 Yervand Kochar street, Yerevan, RA"
    },
    {
      image:EmailImg, href:"mailto:info@gatewayarmenia.org", text:"info@gatewayarmenia.org"
    },
    {
      image:TelImg, href:"tel:+37455212228", text:"+(374) 55 21 22 36 "
    },
  ]

  return (
    <>
      <div className='w-full py-6 bg-black max-xl:px-6'>
        <div className='flex max-w-screen-xl mx-auto max-md:space-y-4 md:space-x-4 max-md:flex-wrap'>
          <div className='w-full md:w-1/2'>
            <div className='py-4 text-2xl font-bold text-white'>USA</div>
            <Card className='overflow-hidden bg-transparent border rounded-md'>
              <List className='p-0 my-2'>
                {
                  listUsa && listUsa.map((item, index) => {
                    return  <ListItem className='px-3 py-2 font-normal text-white rounded-none sm:text-lg hover:text-main'>
                    <ListItemPrefix>
                    <img src={item.image} alt='Location GateWay' className='w-6 sm:w-8' />
                    </ListItemPrefix>
                    <a href={item.href}>
                      {item.text}
                    </a>
                  </ListItem>
                  })
                }

              </List>
            </Card>
          </div>
          <div className='w-full md:w-1/2'>
            <div className='py-4 text-2xl font-bold text-white'>ARMENIA</div>
            <Card className='overflow-hidden bg-transparent border rounded-md'>
              <List className='p-0 my-2'>
              {
                  listAm && listAm.map((item, index) => {
                    return  <ListItem className='px-3 py-2 font-normal text-white rounded-none sm:text-lg hover:text-main'>
                    <ListItemPrefix>
                    <img src={item.image} alt='Location GateWay' className='w-6 sm:w-8' />
                    </ListItemPrefix>
                    <a href={item.href}>
                      {item.text}
                    </a>
                  </ListItem>
                  })
                }
              </List>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}
